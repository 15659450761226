import { css } from 'react-emotion';
import { theme } from '../../Theme';

export const eztradStyling = css`
  .product-info {
    .header h1 {
      font-weight: 600;
      font-size: 0.875rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-variant: normal;
    }
    .product-container .product-details .price {
      font-weight: 600;
    }
  }

  ${theme.below.sm} {
    border-top: none;
  }
`;
