import { theme } from '../../Theme';
import { css } from 'emotion';
import CrossWhite from '../../../svg/CrossWhite.svg';
import CrossBlack from '../../../svg/CrossBlack.svg';

export const EztradStyling = css`
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};

  .header-container {
    border: none;

    .topnav:first-of-type {
      text-transform: uppercase;
    }

    li.top-nav-list-item svg path {
      fill: ${theme.colors.white};
    }

    .top-nav-list-item > div > ul > li {
      border-color: ${theme.colors.white} !important;
      > a {
        font-weight: 600;
        font-size: 0.875rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-variant: none;
      }
    }

    .cart-button-mobile {
      .badge span {
        background-color: ${theme.colors.teal};
      }
    }

    .menu-icon rect {
      fill: ${theme.colors.white};
    }

    .search-field input {
      border-color: ${theme.colors.grey};
      ::placeholder {
        color: ${theme.colors.grey};
      }
      ::-webkit-search-cancel-button {
        background-image: url(${CrossWhite});
      }
    }

    .logo {
      height: 49px;
      margin-top: -2px;
    }

    label {
      color: ${theme.colors.white};
      font-size: 0.875rem;
      letter-spacing: 0.03em;
      font-variant: normal;
      text-transform: none;
    }

    .submenu-container {
      background-color: ${theme.colors.almostBlack};
      top: 100%;
      justify-content: flex-start; /* Fix for IE */
      > div {
        border: none;
        ul li a {
          color: ${theme.colors.white};
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
    }
  }

  ${theme.below.md} {
    .header-container {
      label {
        font-size: 0.75rem;
      }
      .logo {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .mobile-menu-header {
    border-top: none;
    background-color: ${theme.colors.black};
    .close-button-container {
      svg path {
        stroke: ${theme.colors.white};
      }
      span {
        color: ${theme.colors.white};
        text-transform: none;
        font-variant: normal;
      }
    }
  }

  .mobile-menu-body {
    background-color: ${theme.colors.almostBlack};
    .category-tree,
    .category-tree .sub-categories {
      background-color: ${theme.colors.black};
      border-top-color: ${theme.colors.darkGrey};
      border-bottom-color: ${theme.colors.darkGrey};
      a {
        color: ${theme.colors.white};
        border-color: ${theme.colors.darkGrey};
        text-transform: uppercase;
        font-variant: normal;
        font-size: 0.875rem;
      }

      svg path {
        stroke: ${theme.colors.white};
      }
    }
    .category-tree .sub-categories a {
      text-transform: none;
    }

    .category-tree .sub-categories li {
      border-color: ${theme.colors.darkGrey};
    }

    .mobile-secondary-menu {
      li {
        border-bottom: 1px solid ${theme.colors.darkGrey};
        a {
          color: ${theme.colors.mediumGrey};
        }
      }
    }
  }

  .search-bar {
    .search-bar-input-field {
      input {
        background-color: ${theme.colors.lightGrey};
        color: ${theme.colors.almostBlack};
        border: none;
        ::-webkit-search-cancel-button {
          background-image: url(${CrossBlack});
        }
      }
      button {
        color: ${theme.colors.white};
      }
    }
    .icon-container {
      background-color: transparent;
      svg path {
        fill: ${theme.colors.almostBlack};
      }
    }
  }
`;

export const EztradDesktopCartButtonStyling = ({ isActive }) => css`
  text-transform: none;

  .cart-button-inner {
    font-variant: normal;
    font-size: 0.875rem;
    .badge span {
      background-color: ${theme.colors.teal};
    }
  }

  ${theme.above.md} {
    border-bottom-color: ${isActive ? theme.colors.white : 'transparent'};
    :hover {
      border-bottom-color: ${theme.colors.white};
    }
  }
`;

export const EztradLinkStyling = ({ isActive }) => css`
  font-size: 0.875rem;
  letter-spacing: 0.03em;
  font-variant: normal;
  text-transform: inherit;

  ${theme.above.md} {
    border-bottom-color: ${isActive ? theme.colors.white : 'transparent'};

    :hover {
      border-bottom-color: ${theme.colors.white};
    }
  }

  ${theme.below.md} {
    font-size: 0.75rem;
  }
`;

export const eztradQuickSearchStyling = css`
  border-color: ${theme.colors.mediumGrey};
  ul,
  ul li {
    border-color: ${theme.colors.mediumGrey};
  }
  ul li a {
    color: ${theme.colors.almostBlack};
  }
`;
