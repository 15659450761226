import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import Helmet from 'react-helmet-async';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import { fonts } from './Theme';
import SandstromsFavicon from '../icos/SandstromsFavicon.ico';
import RagardsFavicon from '../icos/RagardsFavicon.ico';

const NotFound = Loadable({
  loader: () => import('./NotFoundPage'),
  loading: LoadingPage
});

const PreviewRoute = Loadable({
  loader: () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  loading: LoadingPage
});

const NavTreePage = Loadable({
  loader: () => import('./NavigationTree/NavTreePage'),
  loading: LoadingPage
});

const LoadableStartPage = Loadable({
  loader: () => import('./StartPage/StartPage'),
  loading: LoadingPage
});

const LoadableContentPage = Loadable({
  loader: () => import('./ContentPage/ContentPage'),
  loading: LoadingPage
});

const LoadableProductPage = Loadable({
  loader: () => import('./ProductPage/ProductPage'),
  loading: LoadingPage
});

const LoadableCategoryPage = Loadable({
  loader: () => import('./CategoryPage/CategoryPage'),
  loading: LoadingPage
});

const LoadableSearchPage = Loadable({
  loader: () => import('./SearchPage/SearchPage'),
  loading: LoadingPage
});

function Shop() {
  const {
    routes,
    apolloConfig: { shopid }
  } = useShopConfig();
  const isEztrad = shopid === 'eztrad';
  return (
    <GenericError>
      <ModalProvider>
        <Container>
          <LoadingBar />
          <CustomFont primaryFont={fonts.primary} injectCss={loadFontCss} />
          <Helmet
            titleTemplate={isEztrad ? '%s - Rågårds' : '%s - Sandströms'}
            defaultTitle={isEztrad ? 'Rågårds' : 'Sandströms'}
          >
            <link
              rel="shortcut icon"
              href={isEztrad ? RagardsFavicon : SandstromsFavicon}
            ></link>
          </Helmet>
          <ProductListProvider queries={productListQueries}>
            <Header />
            <Content>
              <PaginationProvider defaultProductsPerPage={24}>
                <Switch>
                  <Route exact path="/" component={LoadableStartPage} />
                  <Route path="/favourites" component={Favourites} />
                  <Route
                    path={routes.search.path}
                    component={LoadableSearchPage}
                  />
                  <Route path={routes.tree.path} component={NavTreePage} />
                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        productPage={LoadableProductPage}
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        categoryPage={LoadableCategoryPage}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={LoadableCategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
            </Content>
            <Footer />
          </ProductListProvider>
          <ModalRoot />
          <ScrollRestorationHandler
            ignoreForRouteTypes={[
              'sortOrderChange',
              'filterChange',
              'paginationChange'
            ]}
          />
        </Container>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
