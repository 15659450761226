import React from 'react';
import { css, cx } from 'react-emotion';
import { theme } from '../Theme';

const discountBadgeCSS = css`
  position: absolute;
  bottom: 0;
  left: 0;

  .discount-percentage {
    padding: 8px 10px 0 0;
    background: ${theme.colors.white};
    color: ${theme.colors.discountRed};
    font-size: 0.875rem;
    font-weight: 500;
  }

  &.is-product-page {
    .discount-percentage {
      padding: 10px 12px;
      font-size: 1.125rem;
      line-height: 1;
    }
  }
`;

const DiscountBadge = ({ price, previousPrice, productPage }) => {
  const discountPercentageInt = Math.round(
    ((previousPrice - price) / previousPrice) * 100
  );

  return (
    <div
      className={cx(
        'discount-badge',
        discountBadgeCSS,
        productPage ? 'is-product-page' : ''
      )}
    >
      <span className="discount-percentage">{`${discountPercentageInt}%`}</span>
    </div>
  );
};

export default DiscountBadge;
