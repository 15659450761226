import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import useSetQuantity from '@jetshop/core/components/Mutation/useSetQuantity';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Minus } from '../../svg/Minus.svg';
import { ReactComponent as Plus } from '../../svg/Plus.svg';
import { ReactComponent as Trash } from '../../svg/Trash.svg';
import { theme } from '../Theme';
import React from 'react';
import { css } from 'react-emotion';
import { Link } from 'react-router-dom';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import setQuantityMutation from './setQuantityMutation.gql';
import cartQuery from './CartQuery.gql';
import t from '@jetshop/intl';

const containerStyling = css`
  display: flex;
  background: white;
  padding: 0.875rem 0;
  border-bottom: 1px solid ${theme.colors.mediumGrey};
  > :first-child {
    width: 62px;
  }
  > :last-child {
    flex: 1;
    padding-left: 1.3125rem;
  }

  .product-detail {
    display: grid;
    grid-auto-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';

    .product-name-container {
      grid-area: topleft;
      a {
        text-decoration: none;
        span {
          font-weight: 500;
          font-size: 0.6875rem;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: ${theme.colors.grey};
        }
        h2 {
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 180%;
          letter-spacing: 0.01em;
          color: ${theme.colors.almostBlack};
        }
      }
    }

    .quantity-buttons-container button,
    .remove-button-container button {
      background-color: transparent;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      :focus {
        outline: none;
      }
      :hover {
        background-color: ${theme.colors.beige};
      }

      svg {
        width: 13px;
      }
    }

    .quantity-buttons-container {
      display: flex;
      grid-area: bottomleft;
      margin: 0.875rem -0.25rem 0;
      align-items: flex-end;

      button,
      input {
        margin: 0 0.25rem;
      }

      input {
        height: 32px;
        width: 32px;
        border: 1px solid ${theme.colors.lightGrey};
        text-align: center;
        font-size: 0.875rem;
        font-family: ${theme.fonts.primary};
        color: ${theme.colors.black};
      }
    }

    .remove-button-container {
      grid-area: topright;
      display: flex;
      justify-content: flex-end;
    }

    .price-container {
      margin-top: 0.875rem;
      grid-area: bottomright;
      align-items: flex-end;
      display: flex;
      height: 32px;
      flex-direction: column;
      align-self: flex-end;
      justify-content: center;
      > div {
        display: flex;
      }
      .price,
      .new-price {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.875rem;
        color: ${theme.colors.almostBlack};
      }
      .old-price {
        text-decoration-line: line-through;
        font-feature-settings: 'tnum' on, 'onum' on;
        margin-left: 0.75rem;
      }
      .discount-label {
        margin-top: 6px;
      }
      .old-price,
      .discount-label {
        font-weight: 500;
        font-size: 0.75rem;
        color: ${theme.colors.grey};
      }
    }
  }

  ${theme.below.md} {
    .product-detail .price-container {
      height: auto;
      > div {
        flex-direction: column;
        align-items: flex-end;
      }
      .old-price {
        margin-top: 3px;
      }
    }
  }
`;

const CartItem = ({ item }) => {
  const { variantImage, hasVariantImage } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const { setQuantity } = useSetQuantity({
    setQuantityMutation,
    cartQuery
  });

  const { name, subheading, primaryRoute } = item.product;

  return (
    <div className={containerStyling}>
      <div className="image-wrapper">
        {item.product.images.length > 0 && (
          <Image
            aspect="279:371"
            sizes="5rem"
            src={
              hasVariantImage ? variantImage.url : item.product.images[0].url
            }
            alt={
              hasVariantImage ? variantImage.alt : item.product.images[0].alt
            }
            quality={80}
          />
        )}
      </div>
      <div className="product-detail">
        <FlyoutTrigger id="cart-flyout">
          {flyout => (
            <div className="product-name-container" onClick={flyout.hideTarget}>
              <Link to={primaryRoute.path}>
                {subheading && <span>{subheading}</span>}
                <h2 data-testid="item-name">{name}</h2>
              </Link>
            </div>
          )}
        </FlyoutTrigger>

        <div className="remove-button-container">
          <button
            onClick={() =>
              removeFromCart({ itemId: item.id, product: item.product })
            }
            data-testid="remove-from-cart"
          >
            <Trash />
          </button>
        </div>

        <div className="quantity-buttons-container">
          <button
            data-testid="decrement-quantity"
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            <Minus />
          </button>
          <input
            data-testid="item-quantity"
            value={item.quantity}
            onChange={e =>
              setQuantity({ itemId: item.id, quantity: e.target.value })
            }
          />
          <button
            data-testid="increment-quantity"
            onClick={() => incrementQuantity({ itemId: item.id })}
          >
            <Plus />
          </button>
        </div>

        <div className="price-container">
          <Price
            data-testid="item-price"
            price={item.total}
            previousPrice={item.previousTotal}
          />
          {item.total.incVat < item.previousTotal.incVat && (
            <span className="discount-label">{t('Discount price')}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartItem;
