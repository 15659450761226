import EBGEot from './EBGaramond-Regular.eot';
import EBGWoff from './EBGaramond-Regular.woff';
import EBGWoff2 from './EBGaramond-Regular.woff2';
import EBGTtf from './EBGaramond-Regular.ttf';

import EBGSBEot from './EBGaramond-SemiBold.eot';
import EBGSBWoff from './EBGaramond-SemiBold.woff';
import EBGSBWoff2 from './EBGaramond-SemiBold.woff2';
import EBGSBTtf from './EBGaramond-SemiBold.ttf';

import MBEot from './Montserrat-Bold.eot';
import MBWoff from './Montserrat-Bold.woff';
import MBWoff2 from './Montserrat-Bold.woff2';
import MBTtf from './Montserrat-Bold.ttf';

import MMEot from './Montserrat-Medium.eot';
import MMWoff from './Montserrat-Medium.woff';
import MMWoff2 from './Montserrat-Medium.woff2';
import MMTtf from './Montserrat-Medium.ttf';

import MEot from './Montserrat-Regular.eot';
import MWoff from './Montserrat-Regular.woff';
import MWoff2 from './Montserrat-Regular.woff2';
import MTtf from './Montserrat-Regular.ttf';

import RMEot from './Raleway-Medium.eot';
import RMWoff from './Raleway-Medium.woff';
import RMWoff2 from './Raleway-Medium.woff2';
import RMTtf from './Raleway-Medium.ttf';

import REot from './Raleway-Regular.eot';
import RWoff from './Raleway-Regular.woff';
import RWoff2 from './Raleway-Regular.woff2';
import RTtf from './Raleway-Regular.ttf';

import RSBEot from './Raleway-SemiBold.eot';
import RSBWoff from './Raleway-SemiBold.woff';
import RSBWoff2 from './Raleway-SemiBold.woff2';
import RSBTtf from './Raleway-SemiBold.ttf';

import BEot from './Bodoni.eot';
import BWoff from './Bodoni.woff';
import BWoff2 from './Bodoni.woff2';
import BTtf from './Bodoni.ttf';

import { injectGlobal } from 'emotion';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return injectGlobal`
  /* Garamond Regular font */
  @font-face {
    font-family: 'EB Garamond';
    src: url(${EBGEot}); /* IE9 Compat Mode */
    src: local('EB Garamond Regular'), local('EBGaramond-Regular'),
        url(${EBGWoff2}) format('woff2'),
        url(${EBGWoff}) format('woff'),
        url(${EBGTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* Garamond Semi-Bold font */
  @font-face {
    font-family: 'EB Garamond';
    src: url(${EBGSBEot}); /* IE9 Compat Mode */
    src: local('EB Garamond Semi-Bold'), local('EBGaramond-SemiBold'),
        url(${EBGSBWoff2}) format('woff2'),
        url(${EBGSBWoff}) format('woff'),
        url(${EBGSBTtf}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  /* Montserrat Regular font */
  @font-face {
    font-family: 'Montserrat';
    src: url(${MEot}); /* IE9 Compat Mode */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url(${MWoff2}) format('woff2'),
        url(${MWoff}) format('woff'),
        url(${MTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* Montserrat Medium font */
  @font-face {
    font-family: 'Montserrat';
    src: url(${MMEot}); /* IE9 Compat Mode */
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url(${MMWoff2}) format('woff2'),
        url(${MMWoff}) format('woff'),
        url(${MMTtf}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  /* Montserrat Bold font */
  @font-face {
    font-family: 'Montserrat';
    src: url(${MBEot}); /* IE9 Compat Mode */
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url(${MBWoff2}) format('woff2'),
        url(${MBWoff}) format('woff'),
        url(${MBTtf}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

   /* Raleway Regular font */
  @font-face {
    font-family: 'Raleway';
    src: url(${REot}); /* IE9 Compat Mode */
    src: local('Raleway Regular'), local('Raleway-Regular'),
        url(${RWoff2}) format('woff2'),
        url(${RWoff}) format('woff'),
        url(${RTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* Raleway Medium font */
  @font-face {
    font-family: 'Raleway';
    src: url(${RMEot}); /* IE9 Compat Mode */
    src: local('Raleway Medium'), local('Raleway-Medium'),
        url(${RMWoff2}) format('woff2'),
        url(${RMWoff}) format('woff'),
        url(${RMTtf}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  /* Raleway Semi-Bold font */
  @font-face {
    font-family: 'Raleway';
    src: url(${RSBEot}); /* IE9 Compat Mode */
    src: local('Raleway Semi-Bold'), local('Raleway-SemiBold'),
        url(${RSBWoff2}) format('woff2'),
        url(${RSBWoff}) format('woff'),
        url(${RSBTtf}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  /* Bodoni Regular font */
  @font-face {
    font-family: 'Bodoni';
    src: url(${BEot}); /* IE9 Compat Mode */
    src: local('Bodoni Regular'), local('Bodoni-Regular'),
        url(${BWoff2}) format('woff2'),
        url(${BWoff}) format('woff'),
        url(${BTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
`;
}
