import { theme } from '../../Theme';
import { css } from 'emotion';

export const EztradStyling = css`
  border-top: none;
  background-color: ${theme.colors.almostBlack};
  color: ${theme.colors.mediumGrey};

  .top-section {
    img {
      height: 49px;
      margin-bottom: 1.875rem;
    }

    p {
      font-weight: 600;
      letter-spacing: 0.03em;
    }

    a {
      color: ${theme.colors.white};
      text-transform: none;
      font-variant: none;
      :after {
        background-color: ${theme.colors.white};
      }
    }
  }

  .bottom-section {
    a {
      letter-spacing: 0.03em;
      font-weight: 500;
    }

    h2 {
      font-weight: 600;
      color: ${theme.colors.lightGrey};
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-variant: normal;
      font-size: 0.875rem;
    }

    .newsletter {
      input {
        background-color: ${theme.colors.lightGrey};
        border: none;
        ::placeholder {
          color: ${theme.colors.grey};
        }
      }

      button {
        color: ${theme.colors.white};
        background-color: ${theme.colors.darkGrey};
        :hover {
          background-color: ${theme.colors.grey};
        }
      }
    }

    .links ul li a :hover {
      border-bottom: none;
      color: ${theme.colors.white};
    }
  }
`;
