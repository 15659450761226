import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { css } from 'react-emotion';
import { Price } from '../Price';
import { theme } from '../Theme';
import DiscountBadge from './DiscountBadge';

const wrapperStyling = css`
  position: relative;

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    margin: 0 0.375em 3.125rem;
  }

  div[data-flight-image-children] {
    position: absolute !important;
  }

  .product-card-detail {
    margin-top: 0.75rem;

    .sub-name {
      font-weight: 500;
      font-size: 0.6875rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: ${theme.colors.grey};
    }

    h3 {
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 0.01em;
      color: ${theme.colors.grey};
      height: 1.5rem;
      overflow: hidden;
    }
  }

  ${theme.below.sm} {
    a {
      margin-bottom: 1.625rem;
    }
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '279:371',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  forwardRef,
  as = 'li',
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;

  return (
    <Tag
      className="product-card"
      css={[wrapperStyling, className]}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink product={product} {...linkProps}>
        {hasImages ? (
          <Image
            sizes={imageSizes}
            aspect={imageAspect}
            alt={product.images[0].alt}
            src={product.images[0].url}
          >
            <Badges badges={product.badges} />
            {product?.price?.incVat !== product?.previousPrice?.incVat && (
              <DiscountBadge
                previousPrice={product.previousPrice.incVat}
                price={product.price?.incVat}
              />
            )}
          </Image>
        ) : (
          <Image src={transparentDataImg} aspect={imageAspect} />
        )}

        <section className="product-card-detail">
          <header>
            {product.subName && (
              <span className="sub-name">{product.subName}</span>
            )}
            <h3>{product.name}</h3>
          </header>
          <Price price={product.price} previousPrice={product.previousPrice} />
        </section>
      </ProductLink>
    </Tag>
  );
}
