import { theme } from '../Theme';
import { css } from 'react-emotion';

export const eztradStyling = css`
  top: 100%;

  .flyout-container {
    .header h2 {
      font-weight: 600;
      font-size: 0.875rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-variant: normal;
      color: ${theme.colors.black};
    }

    .body-container {
      .price {
        font-weight: 600;
      }
      .buttons-container > button {
        border: 1px solid ${theme.colors.mediumGrey};
      }

      .cart-items {
        margin-top: 1.4375rem;

        .product-detail {
          .quantity-buttons-container button,
          .remove-button-container button {
            :hover {
              background-color: ${theme.colors.lightGrey};
            }
          }

          .price-container {
            > div {
              flex-direction: column;
              align-items: flex-end;
            }

            .price,
            .new-price {
              font-weight: 600;
            }
            .new-price {
              color: ${theme.colors.discountRed};
            }
            .old-price {
              margin-top: 3px;
            }
            .discount-label {
              color: ${theme.colors.almostBlack};
            }
          }
        }
      }

      .total-info-bottom {
        .label {
          font-size: 0.75rem;
        }
        .price {
          font-weight: 600;
        }
      }
    }
  }

  ${theme.below.md} {
    .flyout-container .header {
      border-bottom-color: ${theme.colors.mediumGrey};
      height: 81px;
    }
  }
`;
