import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../../Theme';
import React from 'react';
import styled, { css, cx } from 'react-emotion';
import { ReactComponent as Cross } from '../../../svg/Cross.svg';
import { AddToCartButtonStyles } from '../../ui/Button';
import cartQuery from '../../Cart/CartQuery.gql';
import useShopId from '../../../utils/useShopId';
import { eztradStyling } from './EztradStyling';

const productToastStyling = css`
  ${theme.above.sm} {
    width: 320px;
  }
  ${theme.below.sm} {
    width: 100%;
    border-top: 6px solid ${theme.colors.green};
  }
  background-color: ${theme.colors.white};

  .product-info {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0.75rem;

    .header {
      display: flex;
      justify-content: space-between;
      h1 {
        font-weight: 500;
        font-size: 0.9375rem;
        letter-spacing: 0.05em;
        font-variant: small-caps;
        text-transform: lowercase;
        color: ${theme.colors.black};
      }
      button {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        svg {
          width: 12px;
          height: 12px;
          path {
            stroke: ${theme.colors.black};
          }
        }
      }
    }

    .product-container {
      display: flex;
      margin-top: 0.5rem;

      .product-details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        color: black;

        .subheading {
          font-weight: 500;
          font-size: 0.6875rem;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: ${theme.colors.grey};
          margin-bottom: 6px;
        }
        .product-name {
          font-weight: 500;
          font-size: 0.75rem;
          letter-spacing: 0.01em;
          color: ${theme.colors.almostBlack};
          margin-bottom: 8px;
        }

        .price {
          font-weight: 500;
          font-size: 0.75rem;
          color: ${theme.colors.almostBlack};
        }
      }
    }
  }
`;

const CheckoutButton = styled('a')`
  ${AddToCartButtonStyles};
  justify-content: center;
`;

const ProductImageWrapper = styled('div')`
  width: 3.1875rem;
  margin-right: 0.75rem;
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  a {
    text-decoration: none;
    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }
`;

const Error = styled('p')`
  color: ${theme.colors.statusRed};
  margin-top: 0.5rem;
`;

const ProductToast = ({
  product,
  cart,
  closeToast,
  selectedVariation,
  quantity,
  price,
  error
}) => {
  const track = useTracker();
  const image = selectedVariation?.images[0] || product.images[0];
  const isEztrad = useShopId() === 'eztrad';

  return (
    <aside
      className={cx(productToastStyling, isEztrad && eztradStyling)}
      data-testid="product-toast"
    >
      <ProductCheckoutContainer>
        <div className="product-info">
          <div className="header">
            {error ? (
              <h1>{t('Failed adding to bag')}</h1>
            ) : quantity === 1 ? (
              <h1>{t('Added to bag')}</h1>
            ) : (
              <h1>{t.rich('Added {quantity} items to bag', { quantity })}</h1>
            )}
            <button onClick={closeToast}>
              <Cross />
            </button>
          </div>
          <div className="product-container">
            {product.images.length > 0 && (
              <ProductImageWrapper>
                <Image
                  src={image.url}
                  aspect="279:371"
                  sizes="5rem"
                  alt={image.alt}
                  quality={80}
                />
              </ProductImageWrapper>
            )}
            <div className="product-details">
              {product.subheading && (
                <span className="subheading">{product.subheading}</span>
              )}
              <span className="product-name">{product.name}</span>
              {!error && (
                <Price
                  price={price.price}
                  previousPrice={price.previousPrice}
                />
              )}
              {error && (
                <Error>
                  {t('An error occurred. Details:')}
                  <ul>
                    {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                      error.graphQLErrors.map(
                        ({ message, locations, path }) => (
                          <li key={message}>{message}</li>
                        )
                      )
                    ) : (
                      <li>{error.message}</li>
                    )}
                  </ul>
                </Error>
              )}
            </div>
          </div>
        </div>
        {cart && cart.externalCheckoutUrl && (
          <ChannelContext.Consumer>
            {({ selectedChannel }) => (
              <CheckoutButton
                href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                onClick={() => {
                  track(trackCartCheckoutEvent({ cart }));
                }}
              >
                {t('To checkout')}
              </CheckoutButton>
            )}
          </ChannelContext.Consumer>
        )}
      </ProductCheckoutContainer>
    </aside>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
